<template>
  <div class="uk-section uk-section-default uk-margin-large-top">
    <div class="uk-container">
      <h1 class="uk-article-title">
        <a class="uk-link-reset" href="/paris">Paris, France</a>
      </h1>
      <p class="uk-text-lead">
        Best Bars for a Negroni in town
      </p>

      <div class="uk-child-width-1-2@s uk-grid-match" uk-grid>
        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              The 46 Bar
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.4554471040096!2d2.3580703508083696!3d48.868593607841305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66f97a1680f33%3A0x5f4af261193e707!2sThe+46+Bar!5e0!3m2!1sen!2sit!4v1565211881243!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>46 Rue René Boulanger, 75010 Paris, France</p>
          </div>
        </div>
        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Gravity Bar
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.2109287117737!2d2.3585998508085684!3d48.873255407513035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e0c58e5d957%3A0x5a6d7b58b186d633!2sGravity+Bar!5e0!3m2!1sen!2sit!4v1565211939074!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>44 Rue des Vinaigriers, 75010 Paris, France</p>
          </div>
        </div>
        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Le Crocodile
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2625.6509388906197!2d2.3399739508074906!3d48.84579650944641!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e671c2c9d32e01%3A0x1bf0d7b544643eb0!2sThe+Crocodile!5e0!3m2!1sen!2sit!4v1565212004822!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>6 Rue Royer-Collard, 75005 Paris, France</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    // all titles will be injected into this template via %s
    titleTemplate: '%s - Find The Best Negroni in Paris, France'
  }
}
</script>
