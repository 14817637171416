<template>
  <div>
    <footer class="uk-section uk-section-secondary uk-padding-remove-bottom">
      <div class="uk-container">
        <div class="uk-grid uk-grid-large" data-uk-grid>
          <!-- div class="uk-width-1-2@m" -->
          <div class="uk-width-1-2@m">
            <!-- h5>BADASS NEGRONI</h5 -->

            <article class="uk-article">
              <h1 class="uk-article-title">
                <a class="uk-link-reset" href="/">BADASS NEGRONI</a>
              </h1>
              <p>
                This is a site for Negroni lovers and those who want to find out
                the best place to have one
              </p>

              <div class="uk-grid-small uk-child-width-auto" uk-grid>
                <div>
                  <a class="uk-button uk-button-text" href="/about">About Us</a>
                </div>
                <div>
                  <a
                    class="uk-button uk-button-text"
                    href="best-bars-for-a-negroni"
                    >Recommended Places to Drink Negroni</a
                  >
                </div>
                <div>
                  <a
                    class="uk-button uk-button-text"
                    href="where-did-you-get-a-good-negroni-recently"
                    >Suggest a New Bar</a
                  >
                </div>
              </div>
            </article>

            <!-- p>
              <Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud cillum dolore eu fugiat nulla
              contact to: <a href="#" title="">info@company.com</a>
            </p -->
            <!-- div>
              <a href="" class="uk-icon-button" data-uk-icon="twitter"></a>
              <a href="" class="uk-icon-button" data-uk-icon="facebook"></a>
              <a href="" class="uk-icon-button" data-uk-icon="instagram"></a>
            </div -->
          </div>
          <!-- div class="uk-width-1-6@m" -->
          <div class="uk-width-1-3@m">
            <h5>CONTACT US</h5>
            <ul class="uk-list">
              <!-- li>Email: contact@badassnegroni.com</li -->
              <li>
                <div>
                  <a
                    href="https://twitter.com/badassnegroni"
                    target="_blank"
                    class="uk-icon-button"
                    data-uk-icon="twitter"
                    @click="clickTwitter"
                  ></a>
                  <!-- a href="" class="uk-icon-button" data-uk-icon="facebook"></a -->
                  <a
                    href="https://www.instagram.com/badassnegroni"
                    target="_blank"
                    class="uk-icon-button"
                    data-uk-icon="instagram"
                    @click="clickInstaram"
                  ></a>

                  <a
                    href="mailto:contact@badassnegroni.com"
                    target="_blank"
                    class="uk-icon-button"
                    data-uk-icon="mail"
                    @click="clickEmail"
                  ></a>
                </div>
              </li>
            </ul>

            <!-- h5>PRODUCTS</h5>
            <ul class="uk-list">
              <li>Big Data</li>
              <li>Marketing</li>
              <li>Analytics</li>
              <li>AI Lab</li>
            </ul>
          </div>
          <div class="uk-width-1-6@m">
            <h5>OUR COMPANY</h5>
            <ul class="uk-list">
              <li>Team</li>
              <li>Work</li>
              <li>Culture</li>
              <li>Contact Us</li>
            </ul>
          </div>
          <div class="uk-width-1-6@m">
            <h5>OUR OFFICES</h5>
            <ul class="uk-list">
              <li>London</li>
              <li>Chicago</li>
              <li>Dubai</li>
              <li>Brussels</li>
            </ul -->
          </div>
        </div>
      </div>

      <div class="uk-text-center uk-padding uk-padding-remove-horizontal">
        <span class="uk-text-small uk-text-muted"
          >© 2019 Badass Negroni
          <!-- a href="https://github.com/zzseba78/Kick-Off">Created by KickOff</a>
          | Built with
          <a
            href="http://getuikit.com"
            title="Visit UIkit 3 site"
            target="_blank"
            data-uk-tooltip
            ><span data-uk-icon="uikit"></span></a
        --></span>
      </div>

      <cookie-law theme="dark-lime"></cookie-law>
    </footer>
  </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law'

export default {
  components: { CookieLaw },
  methods: {
    clickTwitter: function() {
      this.$ga.event({
        eventCategory: 'Twitter',
        eventAction: 'Click',
        eventLabel: 'Footer Twitter'
      })
    },
    clickInstaram: function() {
      this.$ga.event({
        eventCategory: 'Instagram',
        eventAction: 'Click',
        eventLabel: 'Footer Instagram'
      })
    },
    clickEmail: function() {
      this.$ga.event({
        eventCategory: 'Email',
        eventAction: 'Click',
        eventLabel: 'Footer Email'
      })
    }
  }
}
</script>
