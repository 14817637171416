<template>
  <div class="uk-section uk-section-default uk-margin-large-top">
    <div class="uk-container">
      <h1 class="uk-article-title">
        <a class="uk-link-reset" href="/boston">Boston, USA</a>
      </h1>
      <p class="uk-text-lead">
        Best Bars for a Negroni in town
      </p>
      <div class="uk-child-width-1-2@s uk-grid-match" uk-grid>
        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Backbar
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2947.242408016125!2d-71.09671454942928!3d42.37998224173455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e370cb01e1d8c5%3A0xd7b5e443ac88b452!2sBackbar!5e0!3m2!1sen!2sit!4v1565245018631!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>7 Sanborn Ct, Somerville, MA 02143, USA</p>
          </div>
        </div>

        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Eastern Standard
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2948.7139605428233!2d-71.09820354943031!3d42.34862144371511!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e37a1d4f4debe9%3A0x2849c1c881c8b4a9!2sEastern+Standard!5e0!3m2!1sen!2sit!4v1565245209975!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>528 Commonwealth Avenue, Boston, MA 02215, USA</p>
          </div>
        </div>

        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              The Hawthorne
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2948.718721824766!2d-71.09756564943027!3d42.34851994372158!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e37a1d39ea44b3%3A0x557bf553f56b75b6!2sThe+Hawthorne!5e0!3m2!1sen!2sit!4v1565245301256!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>500 A Commonwealth Avenue, Boston, MA 02215, USA</p>
          </div>
        </div>

        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Colette Wine Bistro
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2946.807184729941!2d-71.12255954942901!3d42.38925384114868!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3775d2fbd5937%3A0x586c1490de59cfe0!2sColette+Wine+Bistro!5e0!3m2!1sen!2sit!4v1565245397914!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>1924 Massachusetts Ave, Cambridge, MA 02140, USA</p>
          </div>
        </div>

        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              The Automatic
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2947.8687873037707!2d-71.09474604942969!3d42.366635542577576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e370ad955a2a2f%3A0x86aec950002a7b1!2sThe+Automatic!5e0!3m2!1sen!2sit!4v1565245455618!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>50 Hampshire St, Cambridge, MA 02139, USA</p>
          </div>
        </div>

        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              The Gold Bar
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2948.627617897531!2d-71.05042374943038!3d42.3504620435989!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e37a8021cce823%3A0xea6448abb2e948f!2sThe+Gold+Bar!5e0!3m2!1sen!2sit!4v1565245587019!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>354 Congress St, Boston, MA 02210, USA</p>
          </div>
        </div>

        <!-- div>
          <div             uk-lightbox             class="uk-card uk-card-default uk-card-hover uk-card-body"           >
            <h3 class="uk-card-title">The Automatic</h3>
            <p>50 Hampshire St, Cambridge, MA 02139, USA</p>
          </div>
        </div -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    // all titles will be injected into this template via %s
    titleTemplate: '%s - Find The Best Negroni in Boston, USA'
  }
}
</script>
