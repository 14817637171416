<template>
  <div class="uk-section uk-section-default uk-margin-large-top">
    <div class="uk-container">
      <h1 class="uk-article-title">
        <a class="uk-link-reset" href="/tokyo">Tokyo, Japan</a>
      </h1>
      <p class="uk-text-lead">
        Best Bars for a Negroni in town
      </p>

      <div class="uk-child-width-1-2@s uk-grid-match" uk-grid>
        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Mori Bar
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.2516749454753!2d139.759431850358!3d35.67080453805491!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188bef493c291f%3A0x60aba5737f0d4b57!2sMori+Bar!5e0!3m2!1sen!2sit!4v1565205245397!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>
              Japan, 〒104-0061 Tōkyō-to, Chuo City, Ginza, 6-chōme−5−12
              新堀ギタービル銀座
            </p>
          </div>
        </div>

        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Bar Triad
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.1449053701026!2d139.70581995035747!3d35.648801339260984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b4159937c35%3A0xdf98ef85afbc0e85!2sBar+Triad!5e0!3m2!1sen!2sit!4v1565205180493!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>
              Japan, 〒150-0021 Tokyo, Shibuya City, 4, 恵比寿西 1-4-1
              ウチノビル４F
            </p>
          </div>
        </div>

        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Jeremiah Tokyo ジェレマイア
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.402599903037!2d139.7083920503587!3d35.69170913690836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188dbee8ae8ab1%3A0x899320d60e81c4d8!2zSmVyZW1pYWggVG9reW8g44K444Kn44Os44Oe44Kk44Ki!5e0!3m2!1sen!2sit!4v1565205630934!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>
              Japan, 〒160-0022 Tokyo, Shinjuku City, Shinjuku, 5 Chome−4−1
              新宿Ｑフラットビル １F
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    // all titles will be injected into this template via %s
    titleTemplate: '%s - Find The Best Negroni in Tokyo, Japan'
  }
}
</script>
