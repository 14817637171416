<template>
  <div class="uk-section uk-section-default uk-margin-large-top">
    <div class="uk-container">
      <h1 class="uk-article-title">
        <a class="uk-link-reset" href="/seattle">Seattle, USA</a>
      </h1>
      <p class="uk-text-lead">
        Best Bars for a Negroni in town
      </p>
      <div class="uk-child-width-1-2@s uk-grid-match" uk-grid>
        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Canon
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2689.7638733868107!2d-122.31869424924!3d47.61128089554646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54906acedccc024d%3A0x7dfc130bbac8f648!2sCanon!5e0!3m2!1sen!2sit!4v1565211424350!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>928 12th Ave, Seattle, WA 98122, USA</p>
          </div>
        </div>

        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Barnacle
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2687.0725187608627!2d-122.38231704923798!3d47.6635875919313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549015b863d5027b%3A0xef4fc63bc3e62f56!2sBarnacle!5e0!3m2!1sen!2sit!4v1565211501421!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>4743 Ballard Ave NW, Seattle, WA 98107, USA</p>
          </div>
        </div>

        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Rob Roy
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2689.5846641661847!2d-122.34877924923983!3d47.61476519530572!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5490154e7fc7b323%3A0xe77d652d918c26a4!2sRob+Roy!5e0!3m2!1sen!2sit!4v1565211566634!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>2332 2nd Ave, Seattle, WA 98121, USA</p>
          </div>
        </div>

        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Essex
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2686.2559746695565!2d-122.37705224923734!3d47.679448590834575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5490142d23b05fa1%3A0x5a1e4631d9551f4!2sEssex!5e0!3m2!1sen!2sit!4v1565211636205!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>1421 NW 70th St, Seattle, WA 98117, USA</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    // all titles will be injected into this template via %s
    titleTemplate: '%s - Find The Best Negroni in Seattle, USA'
  }
}
</script>
