import UserBarsDB from '@/firebase/user-bars-db'

export default {
  /**
   * Fetch bars of current loggedin user
   */
  getUserBars: async ({ rootState, commit }) => {
    const userBarDb = new UserBarsDB(rootState.authentication.user.id)

    const bars = await userBarDb.readAll()
    commit('setBars', bars)
  },

  /**
   * Create a bar for current loggedin user
   */
  createUserBar: async ({ commit, rootState }, bar) => {
    const userBarDb = new UserBarsDB(rootState.authentication.user.id)

    commit('setBarCreationPending', true)
    const createdBar = await userBarDb.create(bar)
    commit('addBar', createdBar)
    commit('setBarCreationPending', false)
  },

  /**
   * Create a new bar for current loggedin user and reset bar name input
   */
  triggerAddBarAction: ({ dispatch, state, commit }) => {
    if (state.barNameToCreate === '' && state.barAddressToCreate === '') return

    const bar = {
      name: state.barNameToCreate,
      address: state.barAddressToCreate
    }
    commit('setBarNameToCreate', 'barAddressToCreate', '')
    dispatch('createUserBar', bar)
  },

  /**
   * Delete a user bar from its id
   */
  deleteUserBar: async ({ rootState, commit, getters }, barId) => {
    if (getters.isBarDeletionPending(barId)) return

    const userBarsDb = new UserBarsDB(rootState.authentication.user.id)

    commit('addBarDeletionPending', barId)
    await userBarsDb.delete(barId)
    commit('removeBarById', barId)
    commit('removeBarDeletionPending', barId)
  }
}
