<template>
  <div>
    <the-best-negroni-in-town-headline></the-best-negroni-in-town-headline>
    <!-- the-best-negroni-in-your-live-headline>
    </the-best-negroni-in-your-live-headline -->
  </div>
</template>

<script>
// import TheBestNegroniInYourLiveHeadline from '@/components/headlines/TheBestNegroniInYourLiveHeadline.vue'
import TheBestNegroniInTownHeadline from '@/components/headlines/TheBestNegroniInTownHeadline.vue'

export default {
  name: 'Home',
  components: {
    // TheBestNegroniInYourLiveHeadline,
    TheBestNegroniInTownHeadline
  },
  metaInfo: {
    title: 'Badass Negroni',
    titleTemplate: '%s - Where to Find the Best Negroni Cocktails',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Looking for The Best Negroni in Town? Check it out! This is a site for Negroni lovers and those who want to find out the best place to have one'
      }
    ]
  }
}
</script>
