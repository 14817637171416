<template>
  <div class="uk-section uk-section-default uk-margin-large-top">
    <div class="uk-container">
      <h1 class="uk-article-title">
        <a class="uk-link-reset" href="/hong-kong">Hong Kong, China</a>
      </h1>
      <p class="uk-text-lead">
        Best Bars for a Negroni in town
      </p>
      <div class="uk-child-width-1-2@s uk-grid-match" uk-grid>
        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              The Old Man
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.871624669679!2d114.14942815004542!3d22.282852249088062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404007b9476a00d%3A0xd5e900aaa4d75f1f!2sThe+Old+Man!5e0!3m2!1sen!2sit!4v1565244799004!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>
              Lower G/F, 37-39 Aberdeen Street, Soho, Central, Hong Kong, China
            </p>
          </div>
        </div>
        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Coa
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.8401050288944!2d114.14984595004542!3d22.28404594904509!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404007c7773ffff%3A0xb8dd65f0dc2837f6!2sCOA!5e0!3m2!1sen!2sit!4v1565244905628!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>
              Shop A, LG/F Wah Shin House, 6-10 Shin Hing Street, Central, Hong
              Kong
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    // all titles will be injected into this template via %s
    titleTemplate: '%s - Find The Best Negroni in Hong Kong, China'
  }
}
</script>
