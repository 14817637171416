<template>
  <div class="uk-section uk-section-default uk-margin-large-top">
    <div class="uk-container">
      <h1 class="uk-article-title">
        <a class="uk-link-reset" href="/florence">Florence, Italy</a>
      </h1>
      <p class="uk-text-lead">
        Best Bars for a Negroni in town
      </p>
      <div class="uk-child-width-1-2@s uk-grid-match" uk-grid>
        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Caffe Gilli
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2881.0430468856425!2d11.25214015061913!3d43.77196525265895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132a54018fb0b815%3A0x158832340fa89f3c!2sCaff%C3%A8+Gilli!5e0!3m2!1sen!2sit!4v1565209603892!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>Via Roma, 1r, 50123 Florence, Italy</p>
          </div>
        </div>

        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Manifattura
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2881.0448244728555!2d11.247371750619147!3d43.77192835266145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132a56aa348d9005%3A0xbf12308715a37778!2sManifattura!5e0!3m2!1sen!2sit!4v1565209681809!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>Piazza di S. Pancrazio, 1, 50123 Florence, Italy</p>
          </div>
        </div>

        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Mad - Souls & Spirits
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2881.162590576838!2d11.242936650619068!3d43.76948365281991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132a56acdc0421cb%3A0x537d320b87631249!2sMad+-+Souls+%26+Spirits!5e0!3m2!1sen!2sit!4v1565209758445!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>Borgo S. Frediano, 36-38r, 50124 Florence, Italy</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    // all titles will be injected into this template via %s
    titleTemplate: '%s - Find The Best Negroni in Florence, Italy'
  }
}
</script>
