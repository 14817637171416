<template>
  <div class="uk-section uk-section-default">
    <div class="uk-container uk-text-center">
      <h1 class="uk-heading-medium">
        <a class="uk-link-reset" href="/">{{ appTitle }}</a>
      </h1>
      <img
        class="uk-animation-slide-right"
        alt="The Best Negroni"
        src="@/assets/img/negroni.jpeg"
      />
      <p class="uk-text-lead">
        Looking for The Best Negroni in Town ?
      </p>
      <p>
        Find out the best places to drink the Italian Cocktail most famous in
        the world
        <!-- <h1> is Negroni </h1> -->
      </p>
      <button
        class="uk-button uk-button-danger uk-animation-scale-up"
        @click="
          $router.push('/best-bars-for-a-negroni')
          clickCheckItOut()
        "
      >
        Check it out!
        <!-- Take a look --></button
      ><br />
      <p>( A curated list by Cities, States and Continents )</p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    clickCheckItOut: function() {
      this.$ga.event({
        eventCategory: 'HomeButton',
        eventAction: 'Click',
        eventLabel: 'Check It Out Button'
      })
    }
  }
}
</script>

<!-- meta tile & description are now based on vue-meta, 
     the following vue-head has been commented out i
     and is going to be removed soon
-->
<!-- script>
import { mapState } from 'vuex'

export default {
  head: function() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  },
  computed: mapState('app', ['appTitle'])
}
</script -->
