import firebase from 'firebase/app'
import 'firebase/auth'

// The configuration below is not sensitive data. You can serenely add your config here
const config = {
  apiKey: 'AIzaSyCYmzYQyHO78VMadWpaevzizmFeU9YiNE4',
  authDomain: 'badassni-146ae.firebaseapp.com',
  databaseURL: 'https://badassni-146ae.firebaseio.com',
  projectId: 'badassni-146ae',
  storageBucket: 'badassni-146ae.appspot.com',
  messagingSenderId: '137850274644',
  appId: '1:137850274644:web:5bebe18dc16434d3'
}

// firebase.initializeApp(config)
!firebase.apps.length ? firebase.initializeApp(config) : firebase.app()
