<template>
  <div id="app">
    <nav-bar v-if="['home'].indexOf($route.name) < 0"></nav-bar>
    <!-- div class="main-wrapper" -->
    <router-view />
    <!-- /div -->

    <new-content-available-toastr
      v-if="newContentAvailable"
      class="new-content-available-toastr"
      :refreshing-app="refreshingApp"
      @refresh="serviceWorkerSkipWaiting"
    ></new-content-available-toastr>
    <apple-add-to-home-screen-modal
      v-if="showAddToHomeScreenModalForApple"
      class="apple-add-to-home-screen-modal"
      @close="closeAddToHomeScreenModalForApple(false)"
    >
    </apple-add-to-home-screen-modal>
    <sticky-footer></sticky-footer>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'

/* add UIkit */
/* see Uikit with VueJS — Vue cli 3 on Medium */
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
/* add UIkit */

import NavBar from '@/components/layout/NavBar'
import StickyFooter from '@/components/layout/StickyFooter'
import NewContentAvailableToastr from '@/components/NewContentAvailableToastr'
import AppleAddToHomeScreenModal from '@/components/AppleAddToHomeScreenModal'

UIkit.use(Icons)

export default {
  name: 'App',
  //metaInfo: {
  // if no subcomponents specify a metaInfo.title, this title will be used
  //  title: 'Badass Negroni',
  // all titles will be injected into this template
  //  titleTemplate: '%s - Where to Find the Best Negroni Cocktails',
  /*  meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'A list of the best places for a Negronis in town'
      }
    ]
},*/
  components: {
    NavBar,
    NewContentAvailableToastr,
    AppleAddToHomeScreenModal,
    StickyFooter
  },
  computed: {
    ...mapGetters('app', ['newContentAvailable']),
    ...mapState('app', ['showAddToHomeScreenModalForApple', 'refreshingApp'])
  },
  methods: mapActions('app', [
    'closeAddToHomeScreenModalForApple',
    'serviceWorkerSkipWaiting'
  ])
}
</script>

<style lang="less">
@import '../node_modules/uikit/src/less/uikit.less';
@import './assets/less/theme.less';
</style>

<style lang="scss">
body {
  margin: 0;

  a {
    font-weight: 500;
    text-decoration: none;
  }

  #app {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    color: #2c3e50;

    .new-content-available-toastr {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }

    .apple-add-to-home-screen-modal {
      position: absolute;
      bottom: 0;
      right: 0;
      top: 0;
      left: 0;
      height: fit-content;
      width: fit-content;
      margin: auto;
      z-index: 1000;
    }

    .main-wrapper {
      margin-top: 3.6rem;
      padding: 20px;

      .page-wrapper {
        width: 60%;
        margin: auto;

        @media screen and (max-width: 1000px) {
          width: 100%;
        }
      }
    }
  }
}
</style>
