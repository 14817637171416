import state from './bars.state'
import mutations from './bars.mutations'
import actions from './bars.actions'
import getters from './bars.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
