<template>
  <div class="uk-section uk-margin-large-top">
    <div class="uk-container">
      <form
        class="uk-grid-small"
        uk-grid
        method="POST"
        action="https://formspree.io/contact@badassnegroni.com"
      >
        <fieldset class="uk-fieldset">
          <legend class="uk-legend">
            Where did you drink a well done Negroni recently?
          </legend>
          <p>Share your experience with us</p>
          <div class="uk-margin">
            <input
              name="message"
              class="uk-input uk-form-width-large"
              type="text"
              placeholder="Bar name, Address, City, Country"
            />
          </div>
          <div class="uk-margin">
            <input
              type="email"
              name="email"
              class="uk-input uk-form-width-large"
              placeholder="Your Email"
            />
          </div>
          <button type="submit" value="Send" class="uk-button uk-button-danger	">
            Submit
          </button>
        </fieldset>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhereDidYouGetAGoodNegroniRecently',
  metaInfo: {
    title: 'Badass Negroni',
    titleTemplate:
      '%s - Contribute to The List of Top Bars for The Best Negroni',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Click Here to Share With Us Where You Drink A Good Negroni Recently'
      }
    ]
  }
}
</script>
