import { find } from 'lodash'

export default {
  /**
   * Check if a bar has deletion pending
   */
  isBarDeletionPending: state => barId =>
    state.barDeletionPending.includes(barId),

  /**
   * Get bar by id
   */
  getBarById: state => barId => find(state.bars, bar => bar.id === barId)
}
