<template>
  <div class="uk-section uk-section-default uk-margin-large-top">
    <div class="uk-container">
      <h1 class="uk-article-title">
        <a class="uk-link-reset" href="/sydney">Sydney, Australia</a>
      </h1>
      <p class="uk-text-lead">
        Best Bars for a Negroni in town
      </p>

      <div class="uk-child-width-1-2@s uk-grid-match" uk-grid>
        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Moyas Juniper Lounge
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d52996.39015198111!2d151.1496364!3d-33.8825866!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12b1d98501cdcd%3A0xac8af54a8783ed87!2sMoyas%20Juniper%20Lounge!5e0!3m2!1sen!2sit!4v1570993730382!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>101 Regent St, Redfern NSW 2016, Sydney, Australia</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    // all titles will be injected into this template via %s
    titleTemplate: '%s - Find The Best Negroni in Milan, Italy'
  }
}
</script>
