<template>
  <div>
    <div class="uk-section uk-margin-large-top">
      <div class="uk-container">
        <article class="uk-article .uk-text-justify">
          <h1 class="uk-article-title">
            <a class="uk-link-reset" href="/about">About Badass Negroni</a>
          </h1>

          <p>
            Badass Negroni was born of a need.
          </p>

          <p>
            Too many times we came across pretentious bars that passed off
            low-quality Negronis, prepared with bad ingredients by baristas who
            wouldn't know how to mix a gin and tonic.
          </p>

          <p>
            We do not think it is acceptable to spend, money, calories and
            evenings drinking badly, regretting that you did not stay on the
            couch at home to drink S.Pellegrino Sparkling Water.
          </p>

          <p>
            Badass Negroni is the reference site for those looking for the best
            Negroni available nearby. We drink responsibly, we respect our
            health, but above all we drink well.
          </p>

          <p>
            Here, you are going to discover about The Top Negroni Makers With No
            Compromise, meaning we’re not looking at Trending Places as much as
            the Best Negroni Cocktails you can have in Town.
          </p>

          <p>
            Our evaluations are based, in order of importance, on the quality of
            the ingredients, the capacity of the Bartender, the hospitality of
            the Bar.
          </p>

          <div>
            <div class="uk-card uk-card-default uk-card-body">
              <p class="uk-text-uppercase">
                If you would like to contribute, making
                <a class="uk-text-danger" href="/best-bars-for-a-negroni">
                  this list
                </a>
                even more accurate and alive, suggesting a new Bar or even to
                disagree on our evaluation, please consider to send us a line to

                <a
                  target="_blank"
                  class="uk-text-danger"
                  href="mailto:contact@badassnegroni.com"
                  title="Badass Negroni Email"
                  @click="clickEmail"
                  >contact@badassnegroni.com</a
                >
              </p>
            </div>
          </div>

          <div
            class="uk-grid-small uk-child-width-auto uk-align-right uk-margin-medium-top"
            uk-grid
          >
            <div>
              <p class="uk-article-meta">
                by <a href="/">Badass Negroni Crew</a>
              </p>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  metaInfo: {
    title: 'About Badass Negroni',
    titleTemplate: '%s - What We Do and How We Got Here',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'We are maintaining a curated list of bars where we had our best Negroni drinking experiences around the world. Check it out!'
      }
    ]
  },
  methods: {
    clickEmail: function() {
      this.$ga.event({
        eventCategory: 'Email',
        eventAction: 'Click',
        eventLabel: 'Email from ABOUT'
      })
    }
  }
}
</script>
