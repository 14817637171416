<template>
  <div class="uk-section uk-section-default uk-margin-large-top">
    <div class="uk-container">
      <h1 class="uk-article-title">
        <a class="uk-link-reset" href="/milan">Milan, Italy</a>
      </h1>
      <p class="uk-text-lead">
        Best Bars for a Negroni in town
      </p>

      <div class="uk-child-width-1-2@s uk-grid-match" uk-grid>
        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Mag Cafè
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2798.919491552831!2d9.171296950679446!3d45.45127844222484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c3fa240953e9%3A0x19bd9a4e39370d05!2zTWFnIENhZsOo!5e0!3m2!1sen!2sit!4v1565125057850!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>Ripa di Porta Ticinese, 43, 20143 Milan, Italy</p>
          </div>
        </div>

        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Bar Basso
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2797.657628822097!2d9.215412450680402!3d45.47670034052843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c6ec85e88aa3%3A0x8c6ed7e9fc8a9e1d!2sBasso+Bar!5e0!3m2!1sen!2sit!4v1565194407193!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>Via Plinio 39, Milan, Italy</p>
          </div>
        </div>
        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Barba
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2797.4456155632242!2d9.199536050680546!3d45.480970540243455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c6c5e3c87fe5%3A0x410a474dffe4a656!2sBarba!5e0!3m2!1sen!2sit!4v1565194480436!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>Via San Gregorio 40, Milan, Italy</p>
          </div>
        </div>
        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Rebelot del Pont
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2798.928930403032!2d9.170094850679469!3d45.45108824223755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c3fa15603cef%3A0x5972e5db459e7390!2sRebelot!5e0!3m2!1sen!2sit!4v1565194672629!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>Ripa di Porta Ticinese 55, Milan, Italy</p>
          </div>
        </div>
        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Pinch
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2798.9468700885127!2d9.1683912506794!3d45.45072674226154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c3fa884313e5%3A0x81776ef55ae55971!2sPINCH+Spirits+%26+Kitchen!5e0!3m2!1sen!2sit!4v1565194749949!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>Ripa di Porta Ticinese 63, Milan, Italy</p>
          </div>
        </div>
        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Rotonda Bistrò
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2798.511010901547!2d9.202503650679757!3d45.4595090416756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c6a1d95377ad%3A0xb0f152cd43c53b0b!2sRotonda+Bistr%C3%B2!5e0!3m2!1sen!2sit!4v1565289720508!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>via Enrico Besana 12, Milan, Italy</p>
          </div>
        </div>
        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Rita
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2798.963018191434!2d9.167745050679471!3d45.450401342283314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c3f07de2980b%3A0xae2361d5bfc1cbff!2sRita!5e0!3m2!1sen!2sit!4v1565201279733!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>via Angelo Fumagalli 1, Milan, Italy</p>
          </div>
        </div>
        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Cafè Gorille
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2797.214206373932!2d9.189434550680716!3d45.48563103993242!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c6cd4505c40b%3A0x8c376f0296bea524!2sCaf%C3%A9+Gorille!5e0!3m2!1sen!2sit!4v1565201654524!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>Via Gaetano de Castillia 20, Milan, Italy</p>
          </div>
        </div>
        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Drinc Cocktail & Conversation
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2797.6206212105985!2d9.213429950680434!3d45.47744574047875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c6eba1d70b07%3A0xd4a00d629dd21912!2sdrinc.+Cocktail+%26+Conversation!5e0!3m2!1sen!2sit!4v1565201849859!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>via Plinio 32, Milan, Italy</p>
          </div>
        </div>
        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Elettrauto Cadore
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2798.558310781651!2d9.211117450679705!3d45.45855604173933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c69c43f01ffd%3A0x59c3ffbfd341147a!2sElettrauto+Cadore!5e0!3m2!1sen!2sit!4v1565202124326!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>via Giacomo Pinaroli 7, Milan, Italy</p>
          </div>
        </div>
        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Gin012
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2798.879765664876!2d9.173098250679507!3d45.452078942171376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c3f9754bac5f%3A0xb4c81ccc757f676b!2sGinO12!5e0!3m2!1sen!2sit!4v1565202201559!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>Alzaia Naviglio Grande 12, Milan, Italy</p>
          </div>
        </div>
        <div>
          <div
            uk-lightbox
            class="uk-card uk-card-default uk-card-hover uk-card-body"
          >
            <h3 class="uk-card-title">
              Lacerba
              <ul class="uk-iconnav uk-align-right">
                <li>
                  <a
                    class="uk-iconnav uk-card-hover"
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2798.743666238666!2d9.196958350679603!3d45.45482134198842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c41e61eaa2a9%3A0x7198939d1df942f0!2sLacerba!5e0!3m2!1sen!2sit!4v1565202368131!5m2!1sen!2sit"
                    data-caption="Google Maps"
                    data-type="iframe"
                    ><img
                      width="100px"
                      height="100px"
                      src="../assets/images/google-maps.png"
                      alt="google-map"
                  /></a>
                </li>
              </ul>
            </h3>
            <p>Via Orti 4, Milan, Italy</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    // all titles will be injected into this template via %s
    titleTemplate: '%s - Find The Best Negroni in Milan, Italy'
  }
}
</script>
