import Vue from 'vue'
import Router from 'vue-router'
// import Head from 'vue-head'
import { isNil } from 'lodash'
import Home from '@/views/Home'
import About from '@/views/About'

/* Cities */
import BestNegroniInMilan from '@/views/BestNegroniInMilan'
import BestNegroniInLondon from '@/views/BestNegroniInLondon'
import BestNegroniInParis from '@/views/BestNegroniInParis'
import BestNegroniInMalaga from '@/views/BestNegroniInMalaga'
import BestNegroniInLisbon from '@/views/BestNegroniInLisbon'
import BestNegroniInSeville from '@/views/BestNegroniInSeville'
import BestNegroniInNewYorkCity from '@/views/BestNegroniInNewYorkCity'
import BestNegroniInHongKong from '@/views/BestNegroniInHongKong'
import BestNegroniInFlorence from '@/views/BestNegroniInFlorence'
import BestNegroniInBoston from '@/views/BestNegroniInBoston'
import BestNegroniInChicago from '@/views/BestNegroniInChicago'
import BestNegroniInLosAngeles from '@/views/BestNegroniInLosAngeles'
import BestNegroniInTokyo from '@/views/BestNegroniInTokyo'
import BestNegroniInBangkok from '@/views/BestNegroniInBangkok'
import BestNegroniInSeattle from '@/views/BestNegroniInSeattle'
import BestNegroniInSanFrancisco from '@/views/BestNegroniInSanFrancisco'
import BestNegroniInSydney from '@/views/BestNegroniInSydney'
/* End - Cities */

/* Headlines */
import WhereDidYouDrinkAWellDoneNegroniRecently from '@/views/WhereDidYouDrinkAWellDoneNegroniRecently'
import WhereDidYouGetAGoodNegroniRecently from '@/views/WhereDidYouGetAGoodNegroniRecently'
/* End - Headlines */

// import CheckLogin from '@/views/CheckLogin'
import store from '@/store'

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */
/*
Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE
}) */

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/about',
      name: 'about',
      component: About,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/best-bars-for-a-negroni',
      name: 'best-bars-for-a-negroni',
      meta: {
        authNotRequired: true
      },
      component: () =>
        import(/* webpackChunkName: "grid-responsive-cards" */ '@/components/GridResponsiveCards')
    },

    /* Headlines */
    {
      path: '/where-did-you-drink-a-well-done-negroni-recently',
      name: 'where-did-you-drink-a-well-done-negroni-recently',
      component: WhereDidYouDrinkAWellDoneNegroniRecently,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/where-did-you-get-a-good-negroni-recently',
      name: 'where-did-you-get-a-good-negroni-recently',
      component: WhereDidYouGetAGoodNegroniRecently,
      meta: {
        authNotRequired: true
      }
    },
    /* End - Headlines */

    /* City Routers */
    {
      path: '/best-negroni-in-milan',
      name: 'best-negroni-in-milan',
      component: BestNegroniInMilan,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/best-negroni-in-london',
      name: 'best-negroni-in-london',
      component: BestNegroniInLondon,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/best-negroni-in-paris',
      name: 'best-negroni-in-paris',
      component: BestNegroniInParis,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/best-negroni-in-malaga',
      name: 'best-negroni-in-malaga',
      component: BestNegroniInMalaga,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/best-negroni-in-lisbon',
      name: 'best-negroni-in-lisbon',
      component: BestNegroniInLisbon,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/best-negroni-in-seville',
      name: 'best-negroni-in-seville',
      component: BestNegroniInSeville,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/best-negroni-in-new-york-city',
      name: 'best-negroni-in-new-york-city',
      component: BestNegroniInNewYorkCity,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/best-negroni-in-hong-kong',
      name: 'best-negroni-in-hong-kong',
      component: BestNegroniInHongKong,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/best-negroni-in-florence',
      name: 'best-negroni-in-florence',
      component: BestNegroniInFlorence,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/best-negroni-in-boston',
      name: 'best-negroni-in-bostone',
      component: BestNegroniInBoston,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/best-negroni-in-Chicago',
      name: 'best-negroni-in-Chicago',
      component: BestNegroniInChicago,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/best-negroni-in-los-angeles',
      name: 'best-negroni-in-los-angeles',
      component: BestNegroniInLosAngeles,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/best-negroni-in-tokyo',
      name: 'best-negroni-in-tokyo',
      component: BestNegroniInTokyo,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/best-negroni-in-bangkok',
      name: 'best-negroni-in-bangkok',
      component: BestNegroniInBangkok,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/best-negroni-in-seattle',
      name: 'best-negroni-in-seattle',
      component: BestNegroniInSeattle,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/best-negroni-in-san-francisco',
      name: 'best-negroni-in-san-francisco',
      component: BestNegroniInSanFrancisco,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/best-negroni-in-sydney',
      name: 'best-negroni-in-sydney',
      component: BestNegroniInSydney,
      meta: {
        authNotRequired: true
      }
    },
    /* City Routers */

    { path: '*', redirect: '/' }
  ]
})

/**
 * Handle user redirections
 */
router.beforeEach((to, from, next) => {
  if (
    !(to.meta && to.meta.authNotRequired) &&
    isNil(store.state.authentication.user)
  ) {
    const path =
      store.state.authentication.user === null ? '/login' : '/check-login'
    return next(`${path}?redirectUrl=${to.path}`)
  }
  next()
})

export default router
