export default {
  /* Bar input name */
  setBarNameToCreate: (state, barNameToCreate) =>
    (state.barNameToCreate = barNameToCreate),

  /* Bar input address*/
  setBarAddressToCreate: (state, barAddressToCreate) =>
    (state.barAddressToCreate = barAddressToCreate),

  /* Bars */
  setBars: (state, bars) => (state.bars = bars),
  addBar: (state, bar) => state.bars.push(bar),
  removeBarById: (state, barId) => {
    const index = state.bars.findIndex(bar => bar.id === barId)
    state.bars.splice(index, 1)
  },

  /* Bars deletion */
  addBarDeletionPending: (state, barId) => state.barDeletionPending.push(barId),
  removeBarDeletionPending: (state, barId) => {
    const index = state.bars.findIndex(bar => bar.id === barId)
    state.barDeletionPending.splice(index, 1)
  },

  /* Bar creation */
  setBarCreationPending: (state, value) => (state.barCreationPending = value)
}
